<script>
/* eslint-disable */
import Datepicker from 'vue3-datepicker';
import {ref} from 'vue';

import Switches from 'vue-switches';
import Multiselect from '@vueform/multiselect';

import PageHeader from '@/components/page-header';
import appConfig from '@/app.config';
import Layout from '../../layouts/main';

/**
 * Advanced-form component
 */
export default {
  page: {
    title: 'Form Advanced',
    meta: [{name: 'description', content: appConfig.description}],
  },
  components: {
    Datepicker,
    Switches,
    Multiselect,
    Layout,
    PageHeader,
  },
  data() {
    return {
      picked: ref(new Date()),
      title: 'Form Advanced',
      items: [
        {
          text: 'Forms',
          href: '/',
        },
        {
          text: 'Form Advanced',
          active: true,
        },
      ],
      defaultdate: '',
      autoClose: ref(new Date()),
      daterange: ref(new Date()),

      demoMonth: ref(new Date()),
      demoYear: ref(new Date()),
      time: ref(new Date()),
      enabled: false,
      enabled1: false,
      enabled2: false,
      enabled3: false,
      enabled4: false,
      enabled5: false,
      small: false,
      small1: false,
      small2: false,
      small3: false,
      danger: false,
      info: false,
      success: false,
      primary: false,
      secondary: false,
      customColor: false,
      customColor1: false,
      customColor2: false,
      customColor3: false,
      value: null,
      value1: null,
      options: [
        'Alaska',
        'Hawaii',
        'California',
        'Nevada',
        'Oregon',
        'Washington',
        'Arizona',
        'Colorado',
        'Idaho',
        'Montana',
        'Nebraska',
        'New Mexico',
        'North Dakota',
        'Utah',
        'Wyoming',
        'Alabama',
        'Arkansas',
        'Illinois',
        'Iowa',
      ],
      maxlen: '',
      data: '',
      option: '',
      textarea: '',
      defaultNull: null,
      defaultspinval: 1,
    };
  },
};
</script>

<template>
  <Layout>
    <PageHeader :items="items" :title="title"/>

    <div class="row">
      <div class="col-lg-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Select2</h4>
            <p
              class="card-title-desc"
            >A mobile and touch friendly input spinner component for Bootstrap</p>
            <div class="col-sm-8">
              <label>Single Select</label>
              <multiselect v-model="value" :options="options"></multiselect>

              <label class="mt-3">Multiple Select</label>
              <multiselect v-model="value1" :mode="'multiple'" :options="options"></multiselect>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Css Switch</h4>
            <p class="card-title-desc">Here are a few types of switches.</p>

            <switches v-model="enabled" class="mb-0" type-bold="true"></switches>
            <switches v-model="enabled1" class="ms-1 mb-0" color="warning"
                      type-bold="false"></switches>
            <switches v-model="enabled2" class="ms-1 mb-0" color="danger"
                      type-bold="false"></switches>
            <switches v-model="enabled3" class="ms-1 mb-0" color="primary"
                      type-bold="false"></switches>
            <switches v-model="enabled4" class="ms-1 mb-0" color="success"
                      type-bold="false"></switches>
            <switches v-model="enabled5" class="ms-1 mb-0" color="info"
                      type-bold="false"></switches>
            <h5 class="mt-4 font-size-14 mb-4">Small switch</h5>

            <div>
              <switches v-model="small"></switches>
              <switches v-model="small1" class="ms-1" color="warning"></switches>
              <switches v-model="small2" class="ms-1" color="danger"></switches>
              <switches v-model="small3" class="ms-1" color="success"></switches>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">

      <div class="col-lg-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Date Picker</h4>
            <p class="card-title-desc">Examples of bootstrap datepicker.</p>

            <div>
              <div class="mb-3">
                <label>Default Date Picker</label>
                <br/>
                <datepicker v-model="picked" :first-day-of-week="1" class="form-control" confirm
                            lang="en"></datepicker>
              </div>

              <div class="mb-3">
                <label>Auto close</label>
                <br/>
                <datepicker v-model="autoClose" :first-day-of-week="1" class="form-control"
                            lang="en"></datepicker>
              </div>

              <div class="mb-3">
                <label>Date Range</label>
                <br/>
                <datepicker v-model="daterange" append-to-body class="form-control" confirm
                            lang="en"
                            range></datepicker>
              </div>

              <div class="mb-3">
                <label>Month View</label>
                <br/>
                <datepicker
                  v-model="demoMonth"
                  :startingView="'month'"
                  class="form-control"
                  confirm
                  lang="en"
                  placeholder="Select Month"
                ></datepicker>
              </div>

              <div class="mb-3">
                <label>Year View</label>
                <br/>
                <datepicker
                  v-model="demoYear"
                  :startingView="'year'"
                  class="form-control"
                  confirm
                  lang="en"
                  placeholder="Select Year"
                ></datepicker>
              </div>

              <div class="mb-3">
                <label>Time View</label>
                <br/>
                <datepicker
                  v-model="time"
                  :minimumView="'time'"
                  class="form-control"
                  placeholder="hh:mm:ss a"
                ></datepicker>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End datepicker -->
      <div class="col-lg-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Bootstrap MaxLength</h4>
            <p class="card-title-desc">Uses the HTML5 attribute "maxlength" to work.</p>

            <label class="mb-1 fw-medium">Default usage</label>
            <p
              class="text-muted font-13"
            >The badge will show up by default when the remaining chars are 10 or less:</p>
            <input v-model="maxlen" :maxlength="10" class="form-control" type="text"/>
            <div v-if="maxlen" class="text-center">
              <p
                :class="{ 'bg-success': maxlen.length !== 10,
                            'bg-danger': maxlen.length === 10 }"
                class="badge position-absolute"
              >{{ maxlen.length }} / 10</p>
            </div>

            <div>
              <label class="mb-1 mt-3 fw-medium">Threshold value</label>
              <p class="text-muted font-13">
                Do you want the badge to show up when there are 20 chars or less? Use the
                <code>threshold</code>
                option:
              </p>
              <input v-model="data" :maxlength="25" class="form-control" type="text"/>
              <div class="text-center">
                <p
                  v-if="data"
                  :class="{ 'bg-success': data.length !== 25,
                            'bg-danger': data.length === 25 }"
                  class="badge position-absolute"
                >{{ data.length }} / 25</p>
              </div>
            </div>

            <div>
              <label class="mb-1 mt-3 fw-medium">All the options</label>
              <p class="text-muted font-13">
                Please note: if the
                <code>alwaysShow</code> option is enabled, the
                <code>threshold</code> option
                is
                ignored.
              </p>
              <input v-model="option" :maxlength="25" class="form-control" type="text"/>
              <div class="text-center">
                <p
                  v-if="option"
                  :class="{ 'bg-success': option.length !== 25,
                            'bg-danger': option.length === 25 }"
                  class="badge position-absolute"
                >
                  You typed
                  {{ option.length }} out of 25 chars available.
                </p>
              </div>
            </div>

            <div>
              <label class="mb-1 mt-3 fw-medium">Textarea</label>
              <p
                class="text-muted font-13"
              >Bootstrap maxlength supports textarea as well as inputs. Even on old IE.</p>
              <textarea
                v-model="textarea"
                :maxlength="225"
                class="form-control"
                placeholder="This textarea has a limit of 225 chars."
                rows="3"
              ></textarea>
              <div class="text-center">
                <p
                  v-if="textarea"
                  :class="{ 'bg-success': textarea.length !== 225,
                            'bg-danger': textarea.length === 225 }"
                  class="badge position-absolute"
                >
                  {{ textarea.length }} /
                  225
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
